@use 'src/src/app/scss/abstracts' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11.5px 18px;
}

.profile_block {
  display: flex;
  align-items: center;
  gap: 18px;

  &__avatar {
    position: relative;

    &__icon_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: $color-base-black-200;
      transform: translate(6px, 6px);
    }
  }

  &__gaming_tokens {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
