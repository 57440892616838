@use 'src/src/app/scss/abstracts' as *;

.footer {
  $block-name: &;

  //   #{$block-name} {
  //   }

  color: rgba($color-semantic-third, 0.85);
  padding-top: 20px;
  padding-bottom: 20px;

  &__age {
    padding: 12px;

    @include mq($until: md) {
      padding: 0;
    }

    .icon {
      @include mq($until: md) {
        width: 25px;
        height: 25px;
      }
    }
  }

  &__all-rights {
    font-size: $font-size-xl;
    line-height: $font-line-l;
    padding: 12px;

    @include mq($until: md) {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: $font-size-2xs;
      line-height: $font-line-l;
    }
  }

  &__bottom {
    @include reset-text-indents;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
    color: rgba($color-semantic-third, 0.85);

    @include mq($until: md) {
      margin-top: 50px;
    }
  }

  &__inner {
    text-align: center;
  }

  &__licence-link {
    color: rgba($color-semantic-third, 0.85);
    text-decoration: none;
    padding: 12px;
    font-size: $font-size-xl;
    line-height: $font-line-l;
    transition: color $transition-fast;

    @include mq($until: md) {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: $font-size-2xs;
      line-height: $font-line-l;
    }

    &:hover {
      color: $color-base-white-100;
    }

    &:active {
      color: rgba($color-semantic-third, 0.85);
    }
  }

  &__logo-link {
    margin-top: -3px;
    color: rgba($color-semantic-third, 0.85);
    transition: color $transition-fast;

    @include mq($until: md) {
      margin-top: -1px;
    }

    &:hover {
      color: $color-base-white-100;
    }

    &:active {
      color: rgba($color-semantic-third, 0.85);
    }

    .icon {
      @include mq($until: md) {
        width: 58px;
        height: 20px;
      }
    }
  }

  &__socials {
    margin-top: 68px;
    margin-bottom: -8px;

    @include mq($until: md) {
      margin-top: 40px;
    }
  }

  &__socials-item {
    margin-left: 15px;
    margin-right: 15px;

    @include mq($until: md) {
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  &__socials-link {
    display: block;
    padding: 8px;
    color: rgba($color-semantic-third, 0.85);
    transition: color $transition-fast;

    &:hover {
      color: $color-base-white-100;
    }

    &:active {
      color: rgba($color-semantic-third, 0.85);
    }

    .icon {
      @include mq($until: md) {
        width: 43px;
        height: 40px;
      }
    }
  }

  &__socials-list {
    @include unlist;
    @include reset-text-indents;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @include reset-text-indents;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include mq($until: md) {
      flex-direction: column;
    }
  }

  &__title-image {
    padding: 0 10px;
    margin-left: 25px;

    @include mq($until: md) {
      margin-left: 0;
      order: 1;
      margin-bottom: 21px;
    }

    img {
      @include mq($until: md) {
        width: 41px;
        height: 66px;
      }
    }
  }

  &__title-text {
    text-align: center;
    font-family: $font-family-main;
    font-size: $font-size-6xl;
    font-style: normal;
    font-weight: $font-weight-xl;
    line-height: $font-line-xs;
    letter-spacing: -3.4px;

    @include mq($until: md) {
      font-size: $font-size-3xl;
      line-height: $font-line-xs;
      font-weight: $font-weight-xl;
      letter-spacing: -1.8px;
    }

    &--first {
      @include mq($until: md) {
        order: 2;
        margin-bottom: 4px;
      }
    }

    &--last {
      @include mq($until: md) {
        order: 3;
      }
    }
  }

  &__top {
  }

  &__year {
    padding: 12px;
    font-size: $font-size-xl;
    line-height: $font-line-l;

    @include mq($until: md) {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: $font-size-2xs;
      line-height: $font-line-l;
    }
  }

  &__button {
    position: fixed;
    z-index: $z-index-other;
    right: 10px;
    bottom: 0;
    transform: translate3d(0, 100%, 0);
    transition: transform $transition-fast;
    padding-bottom: 10px;

    @include mq($until: lg) {
      display: none;
    }

    &--show {
      transform: translate3d(0, 0, 0);
    }
  }
}
